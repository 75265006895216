@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: "Noto Sans", sans-serif;
  margin: 0 auto;
}
a{
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.custom-table input, .custom-table select{
  padding: 4px 8px;
  border-radius: 4px;
}
.custom-table label{
  font-weight: 800;
}
.my-table{
  width: 100%;
}
.my-table th{
  padding-bottom: 8px;
}
.my-table td{
  padding: 8px 0;
}
